import { Box, Chip, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { DATE_FORMAT } from '../../../util/date.util';
import { $ } from '../../../util/formatCurrency.util';
import { SplitCoinImage } from '../../common/SplitCoins';

export const MySplitTokenOrders: React.FC = () => {
  return (
    <>
      <SplitCoinsSummary />
      <RecentSplitTokenTransactions />
    </>
  );
};

export const SplitCoinsSummary = () => {
  const { mySplitTokens } = useMySplitTokensContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        textAlign: 'center',
        mt: 2,
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SplitCoinImage large={false} marginRight={0.5} />
          {mySplitTokens.totalTokens}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Total Purchased
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SplitCoinImage large={false} marginRight={0.5} />
          {mySplitTokens.usedTokens}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Total Used
        </Typography>
      </Box>
    </Box>
  );
};

export const RecentSplitTokenTransactions = () => {
  const { mySplitTokenOrders } = useMySplitTokensContext();

  if (mySplitTokenOrders.length === 0) return null;

  return (
    <List>
      {mySplitTokenOrders.map((order) => (
        <React.Fragment key={order.id}>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography component="span">{`${order.tokenQuantity} SplitCoin${
                    order.tokenQuantity > 1 ? 's' : ''
                  }`}</Typography>
                  {order.totalPaid === 0 && <Chip size="small" label="Free" color="success" sx={{ ml: 1 }} />}
                </Box>
              }
              secondary={`${order.totalPaid === 0 ? 'Added' : 'Purchased'} on ${order.createdAt.toFormat(DATE_FORMAT)}`}
            />
            <Typography variant="body2" color="text.secondary">
              {order.totalPaid === 0 ? 'Free' : $(order.totalPaid)}
            </Typography>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};
