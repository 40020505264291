import { Avatar, Box, Chip, styled } from '@mui/material';
import React from 'react';
import { CheckItemSplit } from '../../../models/Check.model';
import { Split } from '../../../models/Split.model';
import { SplitQuantity } from '../../../models/SplitQuantity.model';
import { useCheckContext } from '../hooks/useCheckContext';

const CustomChip = styled(Chip)(({ color }) => ({
  padding: '0 10px',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: color,
  '& .MuiChip-label': {
    padding: 0,
  },
}));

const NumberBadge = styled('span')({
  padding: ' 1px',
  backgroundColor: 'white',
  color: 'black',
  borderRadius: '20%',
  height: '14px',
  minWidth: '16px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '4px',
  fontSize: '12px',
  fontWeight: 'bold',
});

const SplitChip = ({ split, quantity }: { split: Split; quantity: SplitQuantity }) => {
  return (
    <CustomChip
      size="small"
      avatar={
        split.imageUrl ? (
          <Avatar
            src={split.imageUrl || undefined}
            sx={{
              width: 30,
              height: 30,
              bgcolor: split.color,
              color: 'white',
              fontSize: '12px',
              fontWeight: 'bold',
              border: `1px solid white`,
            }}
          />
        ) : undefined
      }
      label={
        <Box sx={{ pl: split.imageUrl ? 0 : 1 }}>
          {split.firstName}
          <NumberBadge>{quantity.toString()}</NumberBadge>
        </Box>
      }
      color={split.color as any}
      sx={{
        pl: 0.5,
        minWidth: 'unset',
        '& .MuiChip-avatar': {
          marginLeft: 0,
          marginRight: 0.5,
        },
      }}
    />
  );
};

export const SplitChips = ({ splits }: { splits: CheckItemSplit[] }) => {
  const { check } = useCheckContext();

  if (!check) return null;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {splits.map((split: CheckItemSplit) => (
        <SplitChip key={split.splitId} split={check.getSplitById(split.splitId)} quantity={split.quantity} />
      ))}
    </Box>
  );
};
