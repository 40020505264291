import { Box, Container, Typography } from '@mui/material';
import { Camera, MoveRight, QrCode, Receipt, Wallet } from 'lucide-react';
import React from 'react';

type Step = {
  number: string;
  icon: React.ReactNode;
  title: string;
  description: string;
};

const steps: Step[] = [
  {
    number: '1',
    icon: <Camera size={32} />,
    title: 'Scan 📸',
    description: 'Scan your receipt and your bill is ready to split.',
  },
  {
    number: '2',
    icon: <QrCode size={32} />,
    title: 'Share 👥',
    description: 'Friends join the Split right at the table – no app needed.',
  },
  {
    number: '3',
    icon: <Receipt size={32} />,
    title: 'Claim ✨',
    description: "Claim what you ordered, and we'll handle the calculations.",
  },
  {
    number: '4',
    icon: <Wallet size={32} />,
    title: 'Get Paid 💰',
    description: 'Receive instant Venmo payments before leaving the restaurant!',
  },
];

const StepDivider = () => (
  <Box
    sx={{
      display: { xs: 'none', md: 'flex' },
      alignItems: 'center',
      color: 'text.secondary',
      position: 'absolute',
      right: '-12px',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    }}
  >
    <MoveRight size={24} />
  </Box>
);

const MobileStepDivider = () => (
  <Box
    sx={{
      display: { xs: 'flex', md: 'none' },
      justifyContent: 'center',
      color: 'text.secondary',
      my: 2,
    }}
  >
    <Box sx={{ transform: 'rotate(90deg)' }}>
      <MoveRight size={20} />
    </Box>
  </Box>
);

export const HowItWorks = () => {
  return (
    <Box sx={{ py: { xs: 8, md: 12 } }}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: 700,
            mb: 2,
          }}
        >
          How Split It Works
        </Typography>
        <Typography
          variant="h3"
          align="center"
          sx={{
            fontSize: { xs: '1.25rem', md: '1.5rem' },
            color: 'text.secondary',
            mb: { xs: 4, md: 8 },
          }}
        >
          Split the bill in 4 easy steps:
        </Typography>

        {/* Desktop View */}
        <Box
          sx={{
            display: { xs: 'none', md: 'grid' },
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 4,
            position: 'relative',
            mb: 8,
          }}
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <StepIcon step={step} />
              <StepContent step={step} />
              {index < steps.length - 1 && <StepDivider />}
            </Box>
          ))}
        </Box>

        {/* Mobile View */}
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
            mb: 8,
          }}
        >
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <StepIcon step={step} small />
                <StepContent step={step} align="left" />
              </Box>
              {index < steps.length - 1 && <MobileStepDivider />}
            </React.Fragment>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const StepIcon = ({ step, small = false }: { step: Step; small?: boolean }) => (
  <Box
    sx={{
      width: small ? 60 : 80,
      height: small ? 60 : 80,
      borderRadius: '50%',
      bgcolor: '#F8FAFC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#2D3436',
      position: 'relative',
      flexShrink: 0,
    }}
  >
    {step.icon}
    <Typography
      sx={{
        position: 'absolute',
        top: -6,
        right: -6,
        width: small ? 24 : 30,
        height: small ? 24 : 30,
        borderRadius: '50%',
        bgcolor: '#A5E0C9',
        color: '#2D3436',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: small ? '0.75rem' : '0.875rem',
        fontWeight: 600,
      }}
    >
      {step.number}
    </Typography>
  </Box>
);

const StepContent = ({ step, align = 'center' }: { step: Step; align?: 'left' | 'right' | 'center' }) => (
  <Box
    sx={{
      textAlign: align,
      flex: 1,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        fontWeight: 600,
        mb: 0.5,
        fontSize: { xs: '1rem', md: '1.25rem' },
      }}
    >
      {step.title}
    </Typography>
    <Typography
      color="text.secondary"
      sx={{
        fontSize: { xs: '0.875rem', md: '1rem' },
      }}
    >
      {step.description}
    </Typography>
  </Box>
);
