import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Card, CardContent, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMySplitTokensContext } from '../../hooks/useMySplitTokensContext';
import { useUserContext } from '../../hooks/useUserContext';
import { MySplitTokenBalance } from './components/MySplitTokenBalance';
import { MySplitTokenOrders } from './components/MySplitTokenOrders';
import { ProSubscriptionCard } from './components/ProSubscriptionCard';

const PurchaseHistoryCard: React.FC<{ isPro: boolean }> = ({ isPro }) => {
  const [isOrdersExpanded, setIsOrdersExpanded] = useState(false);
  const { mySplitTokens } = useMySplitTokensContext();
  const availableTokens = mySplitTokens.availableTokens;

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        sx={{
          p: 2,
          '&:last-child': { pb: 2 },
          cursor: 'pointer',
        }}
        onClick={() => setIsOrdersExpanded(!isOrdersExpanded)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">{isPro ? 'Previous One-Time Purchases' : 'Purchase History'}</Typography>
          <IconButton size="small" sx={{ transform: isOrdersExpanded ? 'rotate(180deg)' : 'none' }}>
            <KeyboardArrowDown />
          </IconButton>
        </Box>
        {!isOrdersExpanded && (
          <Typography variant="body2" color="text.secondary">
            {isPro
              ? 'Click to view your past SplitCoin purchases from before Split It Pro'
              : 'Click to view your SplitCoin purchase history'}
          </Typography>
        )}
      </CardContent>
      <Collapse in={isOrdersExpanded}>
        <CardContent sx={{ pt: 0 }}>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, fontSize: '0.7rem' }}>
            {isPro
              ? `You have ${availableTokens} SplitCoins that are available if you ever downgrade from Split It Pro.`
              : `If you upgrade to Split It Pro, your SplitCoins will be saved and waiting for you if you ever downgrade.`}
          </Typography>
          <MySplitTokenOrders />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export const MySplitTokensContainer: React.FC = () => {
  const { user } = useUserContext();
  const isPro = user?.subscription?.isActive();

  if (isPro) {
    return (
      <Box sx={{ p: 2 }}>
        <ProSubscriptionCard />
        <PurchaseHistoryCard isPro={true} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <MySplitTokenBalance />
      <PurchaseHistoryCard isPro={false} />
    </Box>
  );
};
