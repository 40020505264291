import { Add, ShoppingCart } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { SplitCoinImage, SplitCoins } from '../../common/SplitCoins';

interface MySplitTokenBalanceProps {
  showNewCheckButton?: boolean;
  showBuyButton?: boolean;
}

export const MySplitTokenBalance: React.FC<MySplitTokenBalanceProps> = ({
  showNewCheckButton = true,
  showBuyButton = true,
}) => {
  const { mySplitTokens } = useMySplitTokensContext();

  const balance = mySplitTokens.availableTokens;

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid size={{ xs: 6 }}>
            <Box display="flex" alignItems="center">
              <SplitCoinImage />
              <Typography variant="h6" color="text.primary" sx={{ ml: 1 }}>
                My Balance
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <SplitCoins count={balance} />
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {showNewCheckButton && (
              <Button variant="contained" startIcon={<Add />} fullWidth component={Link} to="/new" sx={{ mb: 1 }}>
                New Split
              </Button>
            )}
            {showBuyButton && (
              <Button
                variant={!showNewCheckButton ? 'contained' : 'outlined'}
                startIcon={<ShoppingCart />}
                fullWidth
                component={Link}
                to="/tokens/buy"
                sx={{ mb: 1 }}
              >
                Buy SplitCoins
              </Button>
            )}
          </Grid>
        </Grid>
        <Alert severity="info">
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
              Want unlimited SplitCoins?
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
              Upgrade to Split It Pro and never worry about running out of SplitCoins.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              component={Link}
              to="/tokens/buy"
              sx={{ fontWeight: 500 }}
            >
              Learn More
            </Button>
          </Box>
        </Alert>
      </CardContent>
    </Card>
  );
};
