import { Chip } from '@mui/material';
import React from 'react';
import { SubscriptionType } from '../../../models/dto/User.dto';
import { FULLY_CLAIMED_COLOR } from '../../../theme';

export const SplitItProChip = ({
  type,
  includeDetails,
}: { type?: SubscriptionType; includeDetails?: boolean } = {}) => {
  let label = 'Split It Pro';
  if (type) {
    label += ` • ${type === SubscriptionType.Annual ? 'Annual' : 'Monthly'}`;
  }

  if (includeDetails) {
    label += ` Plan • ${type === SubscriptionType.Annual ? '$24/year' : '$4/month'}`;
  }

  return (
    <Chip
      label={label}
      size="small"
      sx={{
        backgroundColor: FULLY_CLAIMED_COLOR,
        borderColor: FULLY_CLAIMED_COLOR,
        fontWeight: 'medium',
      }}
      variant="outlined"
    />
  );
};
