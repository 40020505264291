import { Check } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { FULLY_CLAIMED_COLOR } from '../../../theme';
import { SplitItProChip } from '../../user-profile/components/SplitItProChip';

export const SUBSCRIPTION_BENEFITS = [
  'Split every bill without thinking twice',
  'Never run out of SplitCoins again',
  'Less than a coffee per month',
];

export const SubscriptionBenefits: React.FC = () => {
  return (
    <List dense>
      {SUBSCRIPTION_BENEFITS.map((benefit, index) => (
        <ListItem key={index} sx={{ px: 0 }}>
          <Check fontSize="small" sx={{ color: FULLY_CLAIMED_COLOR, mr: 1 }} />
          <Typography variant="body2">{benefit}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

export const SubscribeCard: React.FC = () => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ p: { xs: 2, sm: 2.5 } }}>
        {/* Explainer */}
        <Box>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Get Unlimited SplitCoins
          </Typography>
          <SplitItProChip />
        </Box>

        {/* Benefits */}
        <Box sx={{ mx: 1, my: 1 }}>
          <SubscriptionBenefits />
        </Box>

        {/* Plans */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Annual Plan */}
          <Card sx={{ bgcolor: FULLY_CLAIMED_COLOR }}>
            <CardContent sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6">Annual Plan</Typography>
                <Chip label="Most Popular" size="small" color="secondary" />
              </Box>
              <Typography variant="body2" sx={{ color: 'text.primary', fontWeight: 500, mb: 0.5 }}>
                Save 50% vs. monthly
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                $24/year (just $2 per month)
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: 'black',
                  '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },
                }}
                component={Link}
                to="/tokens/buy?subscription=annual"
              >
                Get Unlimited SplitCoins
              </Button>
            </CardContent>
          </Card>

          {/* Monthly Plan */}
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Flexible monthly billing option
            </Typography>
            <Button
              variant="outlined"
              component={Link}
              to="/tokens/buy?subscription=monthly"
              sx={{ width: '100%', py: 1 }}
            >
              Monthly Plan • $4/month
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
