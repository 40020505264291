import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAlertContext } from '../../context/useAlertContext';
import { usePaymentContext } from '../../hooks/useStripeContext';
import { useUserContext } from '../../hooks/useUserContext';
import { BuySplitTokensCard } from './components/BuySplitTokensCard';
import { SubscribeCard } from './components/SubscribeCard';

export const BuySplitTokens: React.FC = () => {
  const { getPaymentUrl } = usePaymentContext();
  const { setSuccess, setError } = useAlertContext();

  const [searchParams] = useSearchParams();
  const quantity = searchParams.get('count');
  const subscriptionType = searchParams.get('subscription');
  const status = searchParams.get('status');

  const { user } = useUserContext();
  const isPro = user?.subscription?.isActive();

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPaymentUrl = async () => {
      if (!quantity && !subscriptionType) {
        return;
      }

      const url = await getPaymentUrl({
        subscriptionType: subscriptionType || undefined,
        quantity: quantity ? +quantity : undefined,
      });
      if (!url) {
        return;
      }

      setPaymentUrl(url);
    };

    if (!paymentUrl) {
      fetchPaymentUrl();
    }
  }, [quantity, subscriptionType, paymentUrl]);

  useEffect(() => {
    if (status === 'success') {
      const message = searchParams.get('subscription_success')
        ? 'Your subscription was successful! You now have unlimited SplitCoins!'
        : 'Your SplitCoins purchase was successful! Happy splitting!';
      setSuccess(message);
      return;
    }

    if (status === 'cancelled') {
      setError('Your payment was cancelled.');
    }
  }, [status]);

  if (isPro) {
    return <Navigate to="/tokens" />;
  }

  if (paymentUrl) {
    window.location.href = paymentUrl;
    return null;
  }

  if (quantity || subscriptionType) {
    return null;
  }

  if (status === 'success') {
    return <Navigate to="/tokens" />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <SubscribeCard />
      <BuySplitTokensCard />
    </Box>
  );
};
