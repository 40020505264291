import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Box, Container, Grid2 as Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';

export const Footer: React.FC = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }} /> {/* This will push the footer to the bottom */}
      <Box
        component="footer"
        sx={{
          bgcolor: '#F5F7FA',
          py: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
          maxWidth: '100%',
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              <Typography variant="body2" color="text.secondary">
                Thank you for using Split It!
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                © Jab Labs {new Date().getFullYear()}. All rights reserved.
              </Typography>
            </Grid>
            <Grid sx={{ mt: { xs: 2, sm: 0 } }}>
              <Stack direction={{ xs: 'row', sm: 'row' }} spacing={2}>
                <Link
                  href="mailto:support@splitit.dev"
                  title="support@splitit.dev"
                  underline="hover"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                    '&:hover': { color: '#2D3436' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <EmailIcon sx={{ mr: 0.5 }} fontSize="small" />
                  support@splitit.dev
                </Link>
                <Link
                  href="/feedback"
                  title="Help us improve Split It"
                  underline="hover"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem',
                    '&:hover': { color: '#2D3436' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FeedbackIcon sx={{ mr: 0.5 }} fontSize="small" />
                  Help us improve Split It
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
