export type UserDTO = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  created_at: string;
  profile_image_url: string;
  payment_accounts: PaymentAccountsDTO;
  subscription: SubscriptionDTO;
};

export type PaymentAccountsDTO = {
  venmo?: {
    username: string;
  };
};

export enum SubscriptionType {
  Monthly = 'monthly',
  Annual = 'annual',
}

export type SubscriptionDTO = {
  status: string;
  plan_type: SubscriptionType;
  cancel_at_period_end: boolean;
  current_period_end: string;
};
