import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { VENMO_BLUE, VenmoLogo } from './VenmoCard';

export const VenmoDialog: React.FC<{ isModalOpen: boolean; setIsModalOpen: (open: boolean) => void }> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const { user, updateUser } = useUserContext();
  const venmoUsername = user?.venmoUsername;

  const [newUsername, setNewUsername] = useState(venmoUsername);

  const closeModal = () => {
    setIsModalOpen(false);
    setNewUsername(venmoUsername);
  };

  const handleUpdateUsername = () => {
    updateUser({ venmoUsername: newUsername || '' });
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal} aria-labelledby="edit-venmo-modal" maxWidth="xs" fullWidth>
      <DialogTitle id="edit-venmo-modal">
        <VenmoLogo fill={VENMO_BLUE} /> Edit Venmo Info
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="My Venmo Username"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          InputProps={{
            startAdornment: <Typography variant="body1">@</Typography>,
          }}
          sx={{ mt: 1 }}
          helperText="Your Venmo username will be used to receive Split It payments from other users."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>

        <Button variant="contained" onClick={handleUpdateUsername}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
