import { Box, Typography } from '@mui/material';
import { Gift, Star } from 'lucide-react';
import React from 'react';

interface FinalCTAProps {
  actionButton: React.ReactNode;
}

export const FinalCTA: React.FC<FinalCTAProps> = ({ actionButton }) => {
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'secondary.main',
        py: { xs: 8, md: 12 },
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.05,
          background: 'radial-gradient(circle at 50% 50%, #A5E0C9 0%, transparent 70%)',
        }}
      />

      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          maxWidth: '1400px',
          mx: 'auto',
          px: { xs: 2, md: 4 },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: 700,
            mb: 2,
          }}
        >
          Ready to Make Splitting Bills Fun?
        </Typography>

        {actionButton}

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: { xs: 2, sm: 4 },
            opacity: 0.9,
            fontSize: '1.125rem',
            mt: 4,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Gift size={20} />
            <Typography>No credit card required</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Star size={20} />
            <Typography>One free split when you sign up</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
