import { useAuth } from '@clerk/clerk-react';
import { Check } from '@mui/icons-material';
import { Box, Button, Card, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { FULLY_CLAIMED_COLOR } from '../../../theme';

// Free trial benefits
const FREE_TRIAL_BENEFITS = [
  'Experience perfectly fair bill splitting',
  'See exactly who ordered what items',
  'Avoid awkward money conversations',
];

// Pro plan benefits
const PRO_BENEFITS = [
  'Split unlimited bills with friends',
  'Never worry about running out of splits',
  'Less than a coffee per month',
];

export const PricingPlans = () => {
  const { isSignedIn } = useAuth();
  const subscriptionPath = `/tokens/buy?subscription=annual`;

  return (
    <Box sx={{ py: 8, bgcolor: '#EDF1FB' }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h2" sx={{ fontSize: { xs: '2rem', md: '2.5rem' }, fontWeight: 700, mb: 2 }}>
            Simple, Transparent Pricing
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              color: 'text.secondary',
              maxWidth: '600px',
              mx: 'auto',
            }}
          >
            Get unlimited splits with Split It Pro
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4, maxWidth: 900, mx: 'auto' }}>
          {/* Free Card */}
          <Card sx={{ flex: 1, borderRadius: 3, overflow: 'hidden' }}>
            <Box sx={{ p: 4 }}>
              <Typography variant="h5" fontWeight={600} mb={1}>
                Try It Free
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={3}>
                Get one free split when you sign up
              </Typography>
              <Typography variant="h3" fontWeight={700} mb={3}>
                $0
              </Typography>

              <Box sx={{ mb: 4 }}>
                {FREE_TRIAL_BENEFITS.map((benefit) => (
                  <Box key={benefit} sx={{ display: 'flex', alignItems: 'center', mb: 1.5, gap: 1.5 }}>
                    <Check fontSize="small" color="primary" />
                    <Typography>{benefit}</Typography>
                  </Box>
                ))}
              </Box>

              <Button variant="outlined" fullWidth component={Link} to="/dashboard" sx={{ borderRadius: 2, py: 1.5 }}>
                Start Splitting for Free
              </Button>
            </Box>
          </Card>

          {/* Pro Card */}
          <Card
            sx={{
              flex: 1,
              borderRadius: 3,
              position: 'relative',
              bgcolor: FULLY_CLAIMED_COLOR,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 30,
                right: -30,
                transform: 'rotate(45deg)',
                bgcolor: 'black',
                px: 3,
                py: 0.5,
                color: 'white',
                fontSize: '0.75rem',
                fontWeight: 600,
              }}
            >
              MOST POPULAR
            </Box>

            <Box sx={{ p: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="h5" fontWeight={600}>
                  Split It Pro
                </Typography>
              </Box>

              <Typography variant="body1" color="text.secondary" mb={3}>
                Be the hero who picks up every check
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 3 }}>
                <Typography variant="h3" fontWeight={800} mr={1}>
                  $2
                </Typography>
                <Typography color="text.secondary">/month</Typography>
                <Typography variant="body2" color="text.secondary" ml={1}>
                  (billed annually)
                </Typography>
              </Box>

              <Box sx={{ mb: 4 }}>
                {PRO_BENEFITS.map((benefit) => (
                  <Box key={benefit} sx={{ display: 'flex', alignItems: 'center', mb: 1.5, gap: 1.5 }}>
                    <Check sx={{ color: 'black' }} />
                    <Typography fontWeight={500}>{benefit}</Typography>
                  </Box>
                ))}
              </Box>

              <Button
                component={Link}
                to="/tokens/buy"
                variant="contained"
                fullWidth
                sx={{
                  py: 1.5,
                  bgcolor: 'black',
                  '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                  borderRadius: 2,
                }}
              >
                Subscribe to Split It Pro
              </Button>
            </Box>
          </Card>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button component={Link} to="/tokens/buy" variant="text" size="small" sx={{ color: 'text.secondary' }}>
            Prefer to pay as you go? Buy individual bill splits
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
