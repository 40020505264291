export enum ApiRequest {
  // Unauthenticated
  GetCheckByShareCode = 'GetCheckByShareCode',
  JoinCheck = 'JoinCheck',

  // Auth Code
  GetCheck = 'GetCheck',
  ClaimItem = 'ClaimItem',

  // Signed In
  ParseReceipt = 'ParseReceipt',
  CreateCheck = 'CreateCheck',
  GetMyChecks = 'GetMyChecks',
  GetMyUserInfo = 'GetMyUserInfo',
  GetMyUserStats = 'GetMyUserStats',
  CreateUser = 'CreateUser',
  UpdateUser = 'UpdateUser',
  GetMySplitTokens = 'GetMySplitTokens',
  GetMySplitTokensOrders = 'GetMySplitTokensOrders',
  PurchaseSplitTokens = 'PurchaseSplitTokens',
  SubscribeSplitTokens = 'SubscribeSplitTokens',
  GetPortalUrl = 'GetPortalUrl',
}
