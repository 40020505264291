import { Edit } from '@mui/icons-material';
import { Alert, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { VENMO_BLUE } from './VenmoCard';
import { VenmoDialog } from './VenmoDialog';

export const VenmoInfo: React.FC = () => {
  const { user } = useUserContext();
  const [isVenmoModalOpen, setIsVenmoModalOpen] = useState(false);

  const username = user?.venmoUsername;

  return (
    <>
      <Alert
        action={
          <IconButton size="small" onClick={() => setIsVenmoModalOpen(true)}>
            <Edit fontSize="small" />
          </IconButton>
        }
        severity={username ? 'info' : 'warning'}
      >
        {username ? (
          <>
            Venmo payments will be sent to{' '}
            <a
              href={`https://venmo.com/u/${username}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: VENMO_BLUE, textDecoration: 'none', fontWeight: 'bold' }}
            >
              @{username}
            </a>
          </>
        ) : (
          <>Add Venmo username to receive payments</>
        )}
      </Alert>
      <VenmoDialog isModalOpen={isVenmoModalOpen} setIsModalOpen={setIsVenmoModalOpen} />
    </>
  );
};
