import { Box, Button, Typography } from '@mui/material';
import { PartyPopper, QrCode, Receipt, Wallet } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FinalCTA } from './components/FinalCTA';
import { HowItWorks } from './components/HowItWorks';
import { PricingPlans } from './components/PricingPlans';
import { ROICalculator } from './components/ROICalculator';
import { SocialProof } from './components/SocialProof';
import { WhyEveryoneLovesIt } from './components/WhyEveryoneLovesIt';

export const HomeContainer = () => {
  const ActionButton = () => {
    return (
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/dashboard"
        sx={{
          py: 2,
          px: 4,
          fontSize: '1.1rem',
          fontWeight: 600,
          borderRadius: 2,
          textTransform: 'none',
          bgcolor: '#2D3436',
          '&:hover': {
            bgcolor: '#1a1f20',
            transform: 'translateY(-2px)',
          },
          transition: 'all 0.2s',
        }}
        startIcon={<PartyPopper size={20} />}
      >
        Start Splitting for Free
      </Button>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Hero Section */}
      <Box sx={{ width: '100%', bgcolor: '#fff', position: 'relative', overflow: 'hidden', minHeight: '90vh' }}>
        {/* Background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'radial-gradient(circle at 50% 0%, rgba(165, 224, 201, 0.15), transparent 70%)',
            zIndex: 0,
          }}
        />

        {/* Hero Content */}
        <Box
          sx={{
            maxWidth: '1400px',
            mx: 'auto',
            px: { xs: 2, md: 4 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: { xs: 4, md: 8 },
              py: { xs: 8, md: 12 },
              minHeight: '80vh',
            }}
          >
            {/* Left side content */}
            <Box sx={{ flex: 1, maxWidth: { xs: '100%', md: '50%' } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 800,
                  lineHeight: 1.2,
                  mb: 3,
                  background: 'linear-gradient(to right, #2D3436 20%, #78C2A4 80%)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  color: 'transparent',
                  display: 'inline-block',
                }}
              >
                Split It
              </Typography>

              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                  color: 'text.secondary',
                  mb: 2,
                  lineHeight: 1.6,
                }}
              >
                The Fastest Way to Split Your Restaurant Bill
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  color: 'text.secondary',
                  mb: 4,
                  lineHeight: 1.6,
                }}
              >
                Be the hero who picks up the check. No more awkward moments or chasing payments — get paid back
                instantly. 🚀
              </Typography>

              <Box sx={{ display: 'flex', gap: 2, mb: 6 }}>
                <ActionButton />
              </Box>

              {/* Quick benefits */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <PartyPopper size={20} />
                  <Typography>Make bill splitting a fun social moment, not an awkward one</Typography>
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <QrCode size={20} />
                  <Typography>Share instantly with friends right at the table - no app needed</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Receipt size={20} />
                  <Typography>Everyone pays exactly what they ordered, including tax and tip</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Wallet size={20} />
                  <Typography>Get paid back via Venmo before leaving the restaurant</Typography>
                </Box>
              </Box>
            </Box>

            {/* Right side - Restaurant Image */}
            <Box
              sx={{
                flex: 1,
                maxHeight: '500px',
                width: '100%',
                borderRadius: 4,
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="/restaurant.png"
                alt="Friends splitting a restaurant bill"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Social Proof Strip */}
        <SocialProof />
      </Box>

      {/* Main Content Sections */}
      <HowItWorks />
      <WhyEveryoneLovesIt />
      <ROICalculator />
      <PricingPlans />

      {/* Final CTA */}
      <FinalCTA actionButton={<ActionButton />} />
    </Box>
  );
};
