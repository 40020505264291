import { DateTime } from 'luxon';
import { SubscriptionDTO, SubscriptionType, UserDTO } from './dto/User.dto';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  createdAt: DateTime;
  profileImageUrl: string;
  paymentAccounts: {
    venmo?: { username: string };
  };
  subscription: Subscription | null;

  constructor(dto: UserDTO) {
    this.id = dto.id;
    this.firstName = dto.first_name;
    this.lastName = dto.last_name;
    this.email = dto.email;
    this.username = dto.username;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.paymentAccounts = dto.payment_accounts;
    this.profileImageUrl = dto.profile_image_url;
    this.subscription = dto.subscription ? new Subscription(dto.subscription) : null;
  }

  get name() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  get venmoUsername() {
    return this.paymentAccounts?.venmo?.username ?? null;
  }

  get memberSince() {
    return this.createdAt.toFormat('LLLL yyyy');
  }
}

export class Subscription {
  status: string;
  type: SubscriptionType;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: DateTime;

  constructor(dto: SubscriptionDTO) {
    this.status = dto.status;
    this.type = dto.plan_type;
    this.cancelAtPeriodEnd = dto.cancel_at_period_end;
    this.currentPeriodEnd = DateTime.fromISO(dto.current_period_end);
  }

  isActive() {
    return this.status === 'active';
  }
}
