import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { usePaymentContext } from '../../../hooks/useStripeContext';
import { useUserContext } from '../../../hooks/useUserContext';
import { SplitCoinImage } from '../../common/SplitCoins';
import { SplitItProChip } from '../../user-profile/components/SplitItProChip';
import { SubscriptionBenefits } from './SubscribeCard';

export const ProSubscriptionCard: React.FC = () => {
  const { user } = useUserContext();
  const { getPortalUrl } = usePaymentContext();
  const [isLoading, setIsLoading] = useState(false);

  if (!user?.subscription) return null;

  const { type, currentPeriodEnd, cancelAtPeriodEnd } = user.subscription;
  const endDate = currentPeriodEnd.toFormat('MMM d, yyyy');

  const handleManageClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = await getPortalUrl();
      window.location.href = url;
    } catch (error) {
      console.error('Failed to get portal URL:', error);
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          My Subscription
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Typography variant="body2">
            <SplitCoinImage large={false} /> Unlimited SplitCoins
          </Typography>
        </Box>
        <SplitItProChip type={type} includeDetails />

        <Box sx={{ mx: 1, my: 1 }}>
          <SubscriptionBenefits />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {cancelAtPeriodEnd ? `Your subscription will end on ${endDate}` : `Next billing date: ${endDate}`}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={handleManageClick} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Manage Subscription'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
