import { DisplayColorsDTO, SplitDTO, SplitItemDTO } from './dto/Check.dto';
import { SplitQuantity } from './SplitQuantity.model';

export class Split {
  id: number;
  userId?: number;
  name: string;
  imageUrl?: string;
  isClaimed: boolean;
  subtotal: number;
  tax: number;
  tip: number;
  serviceFee: number;
  splitCoinFee: number;
  totalPrice: number;
  displayColors: DisplayColors;
  items: SplitItem[];

  constructor(dto: SplitDTO) {
    this.id = dto.id;
    this.userId = dto.user_id;
    this.name = dto.name;
    this.imageUrl = dto.image_url;
    this.isClaimed = dto.claimed;
    this.subtotal = +dto.subtotal;
    this.tax = +dto.tax;
    this.tip = +dto.tip;
    this.serviceFee = +dto.service_fee;
    this.splitCoinFee = +(dto.split_coin_fee || 0);
    this.totalPrice = +dto.total_price;
    this.displayColors = new DisplayColors(dto.display_colors);
    this.items = dto.items.map((item) => new SplitItem(item));
  }

  get color() {
    return this.displayColors.backgroundColor;
  }

  get firstName() {
    return this.name.split(' ')[0];
  }

  get initials() {
    if (!this.name?.trim()) {
      return '';
    }
    return this.name
      .trim()
      .split(' ')
      .filter((n) => n.length > 0)
      .slice(0, 2)
      .map((n) => n.charAt(0))
      .join('')
      .toUpperCase();
  }
}

export class SplitItem {
  id: number;
  name: string;
  quantity: SplitQuantity;
  unitPrice: number;
  splitPrice: number;

  constructor(dto: SplitItemDTO) {
    this.id = dto.item_id;
    this.name = dto.item_name;
    this.quantity = new SplitQuantity(dto.quantity);
    this.unitPrice = +dto.unit_price;
    this.splitPrice = +dto.split_price;
  }
}

class DisplayColors {
  backgroundColor: string;
  fontColor: string;

  constructor(dto: DisplayColorsDTO) {
    this.backgroundColor = `#${dto.background_color}`;
    this.fontColor = `#${dto.font_color}`;
  }
}
