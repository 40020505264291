import { Box, Slider, Typography } from '@mui/material';
import { Calculator, CreditCard } from 'lucide-react';
import React, { useState } from 'react';

export const ROICalculator = () => {
  const [monthlyBills, setMonthlyBills] = useState<number>(3);
  const [averageBill, setAverageBill] = useState<number>(250);

  // Calculate rewards
  const calculateRewards = () => {
    const yearlyBills = monthlyBills * 12;
    const yearlySpend = averageBill * yearlyBills;
    const creditCardRewards = yearlySpend * 0.04; // 4% rewards on dining
    const splitItAnnualCost = 24; // $24/year
    const netRewards = creditCardRewards - splitItAnnualCost;

    return {
      yearlyBills,
      yearlySpend,
      creditCardRewards,
      splitItAnnualCost,
      netRewards,
    };
  };

  const rewards = calculateRewards();

  const billMarks = [
    { value: 100, label: '$100' },
    { value: 200, label: '$200' },
    { value: 300, label: '$300' },
    { value: 400, label: '$400' },
    { value: 500, label: '$500' },
  ];

  const splitMarks = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#F8FAFC',
        py: { xs: 10, md: 12 },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background decoration */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          background: 'radial-gradient(circle at 50% 0%, rgba(165, 224, 201, 0.15), transparent 70%)',
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          maxWidth: '1400px',
          mx: 'auto',
          px: { xs: 2, md: 4 },
          position: 'relative',
          zIndex: 1,
        }}
      >
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              mb: 3,
            }}
          >
            <CreditCard size={40} color="#2D3436" />
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: '#2D3436',
              mb: 2,
            }}
          >
            Turn Group Dining into Cash Back ✨
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Split It helps you collect rewards on group dining without the anxiety of chasing payments. See how much you
            could earn below.
          </Typography>
        </Box>

        {/* Calculator Card */}
        <Box
          sx={{
            maxWidth: '800px',
            mx: 'auto',
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              p: { xs: 3, md: 4 },
              borderRadius: 2,
              boxShadow: '0 4px 24px rgba(0,0,0,0.05)',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 4,
                color: '#2D3436',
              }}
            >
              <Calculator size={24} />
              Calculate Your Annual Rewards
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                How many bills do you split per month?
              </Typography>
              <Slider
                value={monthlyBills}
                onChange={(_, value) => setMonthlyBills(value as number)}
                min={1}
                max={10}
                marks={splitMarks}
                valueLabelDisplay="auto"
                sx={{
                  color: '#2D3436',
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Average bill amount
              </Typography>
              <Slider
                value={averageBill}
                onChange={(_, value) => setAverageBill(value as number)}
                min={100}
                max={500}
                step={50}
                marks={billMarks}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `$${value}`}
                sx={{
                  color: '#2D3436',
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.75rem',
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                textAlign: 'left',
                py: 4,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              {/* Left side - Net Rewards */}
              <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Your Net Annual Rewards
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    color: '#10B981',
                    fontWeight: 800,
                    fontSize: { xs: '3.5rem', md: '4.5rem' },
                    lineHeight: 1,
                  }}
                >
                  +${rewards.netRewards.toFixed(0)}
                </Typography>
              </Box>

              {/* Right side - Calculation */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  bgcolor: '#F8FAFC',
                  p: 2,
                  borderRadius: 2,
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  minWidth: { xs: '240px', md: '280px' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ fontSize: 'inherit' }}>Cash back earned (4%)</Typography>
                  <Box sx={{ flex: 1, borderBottom: '1px dotted', borderColor: 'divider', mx: 1 }} />
                  <Typography sx={{ fontSize: 'inherit', color: '#10B981', fontWeight: 600 }}>
                    +${rewards.creditCardRewards.toFixed(0)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ fontSize: 'inherit' }}>Split It annual plan</Typography>
                  <Box sx={{ flex: 1, borderBottom: '1px dotted', borderColor: 'divider', mx: 1 }} />
                  <Typography sx={{ fontSize: 'inherit', color: 'black', fontWeight: 500 }}>-$24</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    pt: 0.5,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography sx={{ fontSize: 'inherit', fontWeight: 600 }}>Net rewards</Typography>
                  <Box sx={{ flex: 1, borderBottom: '1px dotted', borderColor: 'divider', mx: 1 }} />
                  <Typography sx={{ fontSize: 'inherit', fontWeight: 700, color: '#10B981' }}>
                    +${rewards.netRewards.toFixed(0)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
