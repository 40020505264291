import { Box, Typography } from '@mui/material';
import React from 'react';

export const SocialProof = () => {
  return (
    <Box sx={{ width: '100%', bgcolor: '#F8FAFC', py: 4 }}>
      <Box
        sx={{
          maxWidth: '1400px',
          mx: 'auto',
          px: { xs: 2, md: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: 4,
          textAlign: 'center',
        }}
      >
        <Box>
          <Typography variant="h3" fontWeight={700} color="primary">
            967
          </Typography>
          <Typography color="text.secondary">Bills Split Successfully</Typography>
        </Box>
        <Box>
          <Typography variant="h3" fontWeight={700} color="primary">
            5,642
          </Typography>
          <Typography color="text.secondary">Happy Friends & Counting</Typography>
        </Box>
        <Box>
          <Typography variant="h3" fontWeight={700} color="primary">
            $250K+
          </Typography>
          <Typography color="text.secondary">Total Amount Split</Typography>
        </Box>
      </Box>
    </Box>
  );
};
