import { Box, Grid, Typography } from '@mui/material';
import { Clock, PartyPopper, Wallet } from 'lucide-react';
import React from 'react';
import { FULLY_CLAIMED_COLOR } from '../../../theme';

type Benefit = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

const benefits: Benefit[] = [
  {
    icon: <PartyPopper size={32} />,
    title: 'Keep the Good Vibes Going',
    description: 'No awkward moments when the check arrives. Just smooth, instant splits.',
  },
  {
    icon: <Wallet size={32} />,
    title: 'Only Pay for What You Ordered',
    description:
      'Enough with "Let\'s just split it evenly." Everyone pays for their items, including their share of tax and tip.',
  },
  {
    icon: <Clock size={32} />,
    title: 'Split Bills in Seconds',
    description: 'No more frustrating calculations or complex math. Just scan and split instantly.',
  },
];

export const WhyEveryoneLovesIt = () => {
  return (
    <Box
      sx={{
        width: '100%',
        py: 8,
        bgcolor: 'white',
      }}
    >
      {/* Title Section */}
      <Box sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
        <Typography
          component="span"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: 700,
            display: 'inline',
            color: '#2D3436',
          }}
        >
          Why Everyone Loves Split It
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '1.25rem', md: '1.5rem' },
            color: 'text.secondary',
            mt: 2,
            maxWidth: '800px',
            mx: 'auto',
          }}
        >
          Make dining out more enjoyable for everyone
        </Typography>
      </Box>

      {/* Benefits Grid */}
      <Box
        sx={{
          width: '100%',
          px: { xs: 2, md: 4 },
          maxWidth: '1400px',
          mx: 'auto',
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={12} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 2, md: 3 },
                  p: { xs: 3, md: 4 },
                  bgcolor: '#F8FAFC',
                  borderRadius: 2,
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    width: { xs: 40, md: 48 },
                    height: { xs: 40, md: 48 },
                    borderRadius: '12px',
                    bgcolor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#2D3436',
                    backgroundColor: FULLY_CLAIMED_COLOR,
                    flexShrink: 0,
                  }}
                >
                  {benefit.icon}
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      mb: 1,
                      fontSize: { xs: '1rem', md: '1.25rem' },
                      color: '#2D3436',
                    }}
                  >
                    {benefit.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '0.875rem', md: '1rem' },
                      lineHeight: 1.6,
                      color: 'text.secondary',
                    }}
                  >
                    {benefit.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
