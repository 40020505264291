import { loadStripe } from '@stripe/stripe-js';
import React, { createContext, useContext, useState } from 'react';
import { ApiRequest } from '../common/api-request.type';
import { useApiCall } from './useApiCall';

interface PaymentContextType {
  getPaymentUrl: (options?: { quantity?: number; subscriptionType?: string }) => Promise<string>;
  getPortalUrl: () => Promise<string>;
  paymentStatus: string | null;
  setPaymentStatus: (status: string | null) => void;
}

const STRIPE_PUBLISHABLE_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_51Q5tTEKKdOfpVZB3pLsWhPYOlLQ7c8HFfoyzrzxBbgeKNdBqBWi77vdkzVwlJLqicZCYvuuQInmTW38wNBwtmXT000C8Vy61SQ'
    : 'pk_test_51Q5tTEKKdOfpVZB3De4XJBXONhKOmaNHP4YUxOBhPbW64ok7nqhoggezX76qpckDFtn4UL20GSR3ENS8JcxVp2j600e3DXNSM7';
export const STRIPE_PROMISE = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export const PaymentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  const callApi = useApiCall();

  const getPaymentUrl = async (options?: { quantity?: number; subscriptionType?: string }): Promise<string> => {
    // If subscriptionType is provided, create a subscription payment link
    if (options?.subscriptionType) {
      const data = await callApi({
        request: ApiRequest.SubscribeSplitTokens,
        endpoint: '/payments/subscriptions/url',
        method: 'POST',
        body: JSON.stringify({ subscription_type: options.subscriptionType }),
      });

      return data.url;
    }

    // Otherwise, create a one-time payment link for tokens
    const data = await callApi({
      request: ApiRequest.PurchaseSplitTokens,
      endpoint: '/payments/url',
      method: 'POST',
      body: JSON.stringify({ quantity: options?.quantity || 1 }),
    });

    return data.url;
  };

  const getPortalUrl = async (): Promise<string> => {
    const data = await callApi({
      request: ApiRequest.GetPortalUrl,
      endpoint: '/payments/portal',
      method: 'GET',
    });

    return data.url;
  };

  return (
    <PaymentContext.Provider value={{ getPaymentUrl, getPortalUrl, paymentStatus, setPaymentStatus }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
